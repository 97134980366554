import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Dot Matrix (to be migrated to a design issue on GitHub)`}</h1>
    <ul>
      <li parentName="ul">{`This page extends `}<a parentName="li" {...{
          "href": "/Visualization-for-Leiden",
          "title": "Visualization for Leiden"
        }}>{`Visualization for Leiden`}</a>{``}</li>
      <li parentName="ul">{`Overall, this is probably not a great idea for the participation interface. If we're trying to simplify, this adds a lot. But for the report, where we want to show details... could be something to pursue.`}</li>
      <li parentName="ul">{`If we could do this as three dots... we get `}<em parentName="li">{`a lot`}</em>{`, amount voted and amount agreed and amount`}</li>
      <li parentName="ul">{`Amount disagreed and amount passed are the difference between the blue and grey circles. This would probably be used to visualize `}<em parentName="li">{`total agreement`}</em>{`, not average.`}
        <ul parentName="li">
          <li parentName="ul">{`So here if we assume light grey we can see the comment `}<inlineCode parentName="li">{`prop 13 has stalled mobility in all forms`}</inlineCode>{` has a lot of voting but zero agreement. If you highlight disagreement, that does a lot to pull out the difference between the groups:`}</li>
          <li parentName="ul"></li>
        </ul>
      </li>
      <li parentName="ul">{`If we can do lighter colors, we can do darker colors for importance? Or black for importance? But what happens when 100% of people thought it was importnat? If it's color, can you even tell? Is it light red dark red light blue dark blue?`}
        <ul parentName="li">
          <li parentName="ul">{`But importance is `}<em parentName="li">{`not`}</em>{` 1:1, if it were there would be no incentive for people to ever not click it, every vote would be important. So if we overlay that, it has to be another 'legend' layer where we explain the math behind it as show here: `}<a parentName="li" {...{
              "href": "https://github.com/compdemocracy/polis-issues/issues/123"
            }}>{`https://github.com/compdemocracy/polis-issues/issues/123`}</a></li>
          <li parentName="ul">{`Maybe it's best that we use importance to surface comments that we otherwise might miss in terms of what is significant to various groups in differentiating them.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Spot ideas`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      